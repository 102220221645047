const mutations = {

    SET_IS_GLOBAL_LOADING(state, value) {
        state.isGlobalLoading = value;
    },

    SET_INTERNAL_SERVER_ERROR(state, value) {
        state.internalServerError = value;
    },

    SET_SNACKBAR(state, value) {
        state.snackbar = value;
    }

};

export default mutations;

<template>
    <h-row justify="center">
        <h-col cols="auto" class="pa-4 text-center" no-padding>
            <v-icon x-large color="black" style="font-size: 100px;">fa-exclamation-circle</v-icon>
            <h1 class="pa-2">Servizio non disponibile...</h1>
            <i>Riprovare più tardi</i>
            <h-row justify="center">
                <v-btn class="mt-4" @click="$store.dispatch('returnFromInternalError')">RITORNA</v-btn>
            </h-row>
        </h-col>
    </h-row>
</template>

<script>
    import HRow from '@/components/generics/HRow';
    import HCol from '@/components/generics/HCol';

    export default {
        name: 'InternalServerError',
        components: {HRow, HCol}
    }
</script>

<style scoped>

</style>

const state = {

    isGlobalLoading: false,

    internalServerError: false,

    snackbar: {
        open: false,
        color: 'error',
        text: ''
    }

};

export default state;

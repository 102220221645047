import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify';
import store from './store'
import router from './router';
import cookie from 'vue-cookies';

Vue.config.productionTip = false;

new Vue(
    {
        vuetify,
        store,
        router,
        cookie,
        render: h => h(App)
    }
).$mount('#app');

const actions = {

    activateGlobalLoading(ctx) {
        ctx.commit('SET_IS_GLOBAL_LOADING', true);
    },
    deactivateGlobalLoading(ctx) {
        ctx.commit('SET_IS_GLOBAL_LOADING', false);
    },

    returnFromInternalError(ctx) {
        ctx.commit('SET_INTERNAL_SERVER_ERROR', false);
    },

    activeSnackbar(ctx, {text, color}) {

        ctx.commit('SET_SNACKBAR', {open: true, text, color: color ? color : 'primary'});

        ctx.dispatch('addNotifica', {text, color});

    },
    closeSnackbar(ctx) {
        ctx.commit('SET_SNACKBAR', {...ctx.state.snackbar, open: false});
    }

};

export default actions;

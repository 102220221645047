const baseRequestActions = {

    defaultRequest(ctx, {api, paylod, doResponse, doClose}) {

        ctx.dispatch('activateGlobalLoading');

        ctx.dispatch(api, paylod).then(
            response => {
                ctx.dispatch('deactivateGlobalLoading');
                doResponse(response.data);
                doClose(true);
            },
            error => {
                ctx.dispatch('error', error);
                ctx.dispatch('deactivateGlobalLoading');
                doClose(false);
            }
        );

    },

    error(ctx, error) {

        if (!error.response || error.response.status >= 500 || error.response.status === 404) {

            ctx.commit('SET_INTERNAL_SERVER_ERROR', true);

        } else if (error.response.status === 401) {

            ctx.dispatch('activeSnackbar', {text: 'Sessione scaduta, accedi nuovamente', color: 'error'});

        } else {
            ctx.dispatch('activeSnackbar', {text: error.response.data, color: 'error'});
        }

    }

}

export default baseRequestActions;

<template>
    <v-app>

        <navbar></navbar>

        <v-main>

            <v-container fluid class="pa-0">

                <h-row v-show="!$store.state.servicesModule.internalServerError">

                    <h-col no-padding>
                        <router-view></router-view>
                    </h-col>

                </h-row>

                <internal-server-error v-show="$store.state.servicesModule.internalServerError"></internal-server-error>
                <snackbar-message></snackbar-message>

            </v-container>

        </v-main>

    </v-app>
</template>

<script>
    import HRow from '@/components/generics/HRow';
    import HCol from '@/components/generics/HCol';
    import Navbar from '@/components/navigation/Navbar';
    import InternalServerError from '@/components/service/InternalServerError';
    import SnackbarMessage from '@/components/service/SnackbarMessage';

    export default {
        name: 'App',
        components: {HRow, HCol, SnackbarMessage, InternalServerError, Navbar},
        computed: {
            website: function () {
                return this.$store.getters.website;
            }
        },
        created() {
            if (!window.location.origin.includes(this.website) || !location.origin.includes(this.website)) {
                location.href = 'https://' + this.website;
            }
        }
    };
</script>

<template>
    <v-app-bar app dark :color="color">
        <v-btn x-large text to="/" exact>
            <v-img src="/assets/LOGO_ORIGINAL.png" max-width=60 max-height=50></v-img>
            <span style="margin-left: 10px;">Marvel Avengers Alliance Reborn</span>
        </v-btn>
        <v-spacer></v-spacer>
        <h1 v-if="!!color">SANDBOX</h1>
        <v-spacer></v-spacer>
    </v-app-bar>
</template>

<script>
    export default {
        name: 'Navbar',
        computed: {
            color: function () {
                return window.location.origin.includes('sandbox') ? 'error' : undefined;
            }
        }
    }
</script>

<style scoped>
    .v-btn:before {
        background-color: transparent;
    }
</style>

import Axios from 'axios';

const getters = {

    axios: () => Axios.create(
        {
            baseURL: window.location.origin === 'http://localhost:8089' ? 'http://localhost:3000/api/' : window.location.origin + '/api/',
            headers: {
                'X-VERSIONE': 150
            }
        }
    )

};

export default getters;

const protectedRoute = [];

const publicRoute = [
    {
        path: '/',
        name: 'Home',
        meta: {title: 'Home'},
        component: () => import('../components/index/Index')
    },
    {
        path: '**',
        name: 'Redirect',
        redirect: '/'
    }
];

export default protectedRoute.concat(publicRoute);

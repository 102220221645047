import Vue from 'vue'
import Vuex from 'vuex'

import authModule from './module/auth';
import apiModule from './module/api';
import servicesModule from './module/services';
import utilsModule from './module/utils';

Vue.use(Vuex);

const store = new Vuex.Store(
    {
        modules: {
            authModule,
            apiModule,
            servicesModule,
            utilsModule
        }
    }
);

export default store;

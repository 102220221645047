const actions = {

    copyToClipboard(ctx, {value, text}) {

        const input = document.createElement('input');
        input.value = value;
        document.body.appendChild(input);
        input.select();
        document.execCommand('copy');
        document.body.removeChild(input);
        ctx.dispatch('activeSnackbar', {text, color: 'success'});

    },

    downlaodToFile(ctx, {data, filename}) {

        const blob = new Blob([data], {type: 'octet/stream'});

        const url = URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.href = url;
        a.download = filename;
        a.click();

        window.URL.revokeObjectURL(url);

    }

};

export default actions;
